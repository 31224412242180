import { Injectable, inject } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';

import { LocalStorageService } from './local-storage.service';
import { INTERNAL_AUTH_CONFIG_ID } from '../auth/config/fusionAuth.config';

/**
 * A service that provides authentication-related functionality.
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly localStorageService = inject(LocalStorageService);

  private readonly internalAuthConfigId = INTERNAL_AUTH_CONFIG_ID;

  public isAuthenticated() {
    return this.oidcSecurityService.isAuthenticated(this.internalAuthConfigId);
  }

  public checkAuth(url?: string) {
    return this.oidcSecurityService.checkAuth(url, this.internalAuthConfigId);
  }

  public login(customRedirectURL: string | null = null) {
    if (customRedirectURL) {
      this.setCustomAuthRedirectURL(customRedirectURL);
    }
    this.oidcSecurityService.authorize(this.internalAuthConfigId);
  }

  public async logout() {
    await lastValueFrom(this.oidcSecurityService.logoff(this.internalAuthConfigId));
    this.clearCustomAuthRedirectURL();
  }

  public getAccessToken() {
    return this.oidcSecurityService.getAccessToken(this.internalAuthConfigId);
  }

  public getState() {
    return this.oidcSecurityService.getState(this.internalAuthConfigId);
  }

  public setCustomAuthRedirectURL(url: string) {
    this.localStorageService.set(LocalStorageService.ItemKey.CustomRedirectURL, url);
  }

  public getCustomAuthRedirectURL() {
    return this.localStorageService.get(LocalStorageService.ItemKey.CustomRedirectURL);
  }

  public clearCustomAuthRedirectURL() {
    this.localStorageService.remove(LocalStorageService.ItemKey.CustomRedirectURL);
  }
}
